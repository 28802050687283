import { get, post, put } from '@/application/api/implementations/app';
import { getPaginated } from '@/application/api/implementations/app/wrapper.js';

export const autocomplete = (...args) =>
  getPaginated(`store/autocomplete`, ...args);

export const index = (...args) => getPaginated(`store`, ...args);

export const show = (id) => get(`store/${id}`);

export const create = (values) => post(`store`, values);

export const update = (values) => put(`store/${values.id}`, values);

export const getImportTemplate = () => get(`store/export`);

export const importStore = (clientId, file) => post(`client/${clientId}/store/csv-import`, file);
