import { Address } from '@/application/models/Address.js';
import Model from './model';

export class Store extends Model {
  id = 0;
  name = '';
  email = '';
  floorplannerProjectId = 0;
  dealerCode = 0;
  dealerLevel = '';
  branchExtension = '';
  phoneNumber = '';
  mobilePhoneNumber = '';
  showroomAddress = new Address();
  shippingAddress = new Address();
  companyName = '';
  billingAddress = new Address();
  storeOwners = [];
  approvers = [];

  mapForRequest() {
    return {
      name: this.name,
      email: this.email,
      dealerCode: this.dealerCode,
      dealerLevel: this.dealerLevel,
      branchExtension: this.branchExtension,
      phoneNumber: this.phoneNumber,
      mobilePhone: this.mobilePhone,
      companyName: this.companyName,
      showroomAddress: this.showroomAddress.mapForRequest(),
      shippingAddress: this.shippingAddress.mapForRequest(),
      billingAddress: this.billingAddress.mapForRequest(),
      storeOwners: this.storeOwners.length > 0 ? this.storeOwners : null,
      approvers: this.approvers.length > 0 ? this.approvers.map(({ id }, index) => ({
        userId: id,
        order: index,
      })) : null,
    };
  }

  mapResponse(data) {
    data.approvers = data.approvers.map(approver => ({
      ...approver,
      text: approver.name,
    }));
    return super.mapResponse(data);
  }
}
