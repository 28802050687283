<template>
  <KListDraggable v-bind="$attrs" v-on="$listeners" @click:delete="removeItem" list-item-class="my-1"/>
</template>

<script>
import { EventBus } from '@/application/EventBus.js'
import KListDraggable from '@/components/crud/fields/KListDraggable.vue';

export default {
  name: 'ApproversList',
  components: {
    KListDraggable,
  },
  methods: {
    removeItem({ list, item}) {
      EventBus.$emit('snackbar', {
        color: 'success',
        text: this.$t('actions.resourceRemoved', { resource: `"${item.text}"` }),
      });
      this.$emit('input', list);
    },
  },
};
</script>
