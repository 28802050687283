<template>
  <k-form-dialog :request="uploadFile"
                 :custom-validation-error-header="errorMessage"
                 :custom-save-button-label="$t('store.import')"
                 v-bind="$attrs" v-on="$listeners">
    <k-field-group language-prefix="store.fields">
      <v-row class="fill-height">
        <v-col cols="6">
          <form-sheet class="fill-height">
            <v-row>
              <v-col>
                <k-btn text @click="handleDownloadImportTemplateClick" class="mb-5">
                  {{ $tc('store.actions.downloadTemplate') }}
                </k-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <KFileField ref="importFileField"
                              required
                              field="file"
                              accept=".csv"
                              v-model="importFile"/>
              </v-col>
            </v-row>
          </form-sheet>
        </v-col>
      </v-row>
    </k-field-group>
  </k-form-dialog>
</template>

<script>
import KFormDialog from '@/components/crud/KFormDialog.vue';
import FormSheet from '@/components/FormSheet.vue';
import KBtn from '@/components/KButton.vue';
import { getImportTemplate, importStore } from '@/modules/store/api';
import KFileField from '@/components/crud/fields/KFileField.vue';
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'ImportForm.vue',
  components: {
    KFieldGroup,
    KFileField,
    KBtn,
    KFormDialog,
    FormSheet,
  },
  data() {
    return {
      importFile: null,
      errorMessage: null,
    };
  },
  computed: {
    ...mapGetters('authorisation', ['getCurrentClientId']),
  },
  methods: {
    // get csv data, make file and invoke download
    async handleDownloadImportTemplateClick() {
      const response = await getImportTemplate();
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = window.document.createElement('a');
      link.href = url;
      link.target = '_blank';
      link.setAttribute('download', `stores.csv`);
      window.document.body.appendChild(link);
      link.click();
    },
    async uploadFile() {
      this.errorMessage = null;
      try {
        await importStore(this.getCurrentClientId, { file: this.importFile[0] });
      } catch (err) {
        if (err.response.status === 422) throw err;
        if (err.response.status === 400) {
          const importMessages = err.response.data?.message || [];
          this.errorMessage = this.$t('store.text.importFailed') + '\n';
          importMessages.forEach(message => {
            this.errorMessage += this.$t('store.text.importError', { row: message.row, type: this.$t(`store.errorTypes.${message.type}`), data: message.data, section: message.section }) + '\n';
          });
        }
        throw err;
      }
    },
    reset() {
      this.importFile = null;
      this.errorMessage = null;
    },
  },
};
</script>

<style scoped>

</style>
