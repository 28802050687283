<template>
  <KPaginatedAutocomplete v-bind="$attrs" v-on="$listeners" :paginator="autocomplete"/>
</template>

<script>
import KPaginatedAutocomplete from '@/components/crud/fields/KPaginatedAutocomplete.vue';
import {autocomplete} from '@/modules/user/api';

export default {
  name: 'StoreOwnersAutocomplete',
  components: {KPaginatedAutocomplete},
  methods: {
    autocomplete
  },
};
</script>
