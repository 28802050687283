import {get, post, put} from '@/application/api/implementations/app';
import {getPaginated} from '@/application/api/implementations/app/wrapper.js';

export const index = (...args) => getPaginated(`user`, ...args);

export const autocomplete = (...args) => getPaginated(`user`, ...args);

export const storeUserAutocomplete = (id, ...args) => getPaginated(`store/${id}/user/autocomplete`, ...args);

export const regions = (clientId, userId, ...args) => getPaginated(`client/${clientId}/user/${userId}/region`, ...args);

export const regionsList = (clientId) => getPaginated(`client/${clientId}/region/show-list`);

export const notificationsList = (clientId) => getPaginated(`client/${clientId}/notification`);

export const show = (id) => get(`user/${id}`);

export const create = (values) => post(`user`, values);

export const update = (values) => put(`user/${values.id}`, values);
