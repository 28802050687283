<template>
  <KPaginatedAutocomplete v-bind="$attrs" v-on="$listeners" :paginator="autocomplete"/>
</template>

<script>
import { User } from '@/application/models/User.js';
import KPaginatedAutocomplete from '@/components/crud/fields/KPaginatedAutocomplete.vue';
import { storeUserAutocomplete } from '@/modules/user/api';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'UsersAutocomplete',
  components: { KPaginatedAutocomplete },
  methods: {
    async autocomplete(...args) {
      const response = await storeUserAutocomplete(this.currentStoreId, ...args);
      response.data.data = response.data.data.map((user) => new User().mapResponse(user))
      return response;
    }
  },
  computed: {
    ...mapGetters('settings', { currentStoreId: 'getCurrentStoreId' }),
  }
};
</script>
