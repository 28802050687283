import {Language} from '@/application/models/Language.js';
import Model from './model';

export class User extends Model {
    id = 0;
    language = new Language();
    name = '';
    firstName = '';
    insertion = '';
    lastName = '';
    initials = '';
    company = '';
    function = '';
    email = '';
    phone = '';
    isActive = false;
    isAdmin = false;
    storeOwner = [];
    favouriteStores = [];
    roleId = 0;
    regions = [];
    selectedStores = [];
    activeNotificationTypeIds = [];

    mapForRequest() {
        const isOwner = this.storeOwner.map(({id}) => id).length
        return {
            locale: this.language.locale,
            name: this.name,
            firstName: this.firstName,
            insertion: this.insertion,
            lastName: this.lastName,
            initials: this.initials,
            company: this.company,
            phone: this.phone,
            function: this.function,
            email: this.email,
            isActive: this.isActive,
            isAdmin: this.isAdmin,
            roleId: this.roleId || null,
            stores: this.selectedStores.length ? this.selectedStores.map(storeId => { return { id: storeId }}) : null,
            activeNotificationTypeIds: this.activeNotificationTypeIds,
            storeOwner: isOwner ? this.storeOwner.map(({id}) => id) : null,
        };
    }
}
