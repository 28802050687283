<template>
  <k-field-group language-prefix="store.filterFields">
    <k-sidebar v-bind="$attrs"
               v-on="$listeners"
               :save-button-text="$t('actions.applyFilters')"
               :title="$t('store.filterTitle')"
               fixed
               right
               temporary
               width="300"
               @click:confirm="handleFilterClick">
      <CountryAutocomplete :return-object="false"
                           v-model="form.countryId"
                           field="country"
                           hide-details
                           item-text="name"
                           item-value="id"
                           class="mb-2 pb-1"/>
    </k-sidebar>
  </k-field-group>
</template>

<script>
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KSidebar from '@/components/layout/KSidebar.vue';
import CountryAutocomplete from '@/modules/country/components/CountryAutocomplete.vue';

export default {
  name: 'StoresFilter',
  inheritAttrs: false,
  components: {
    CountryAutocomplete,
    KSidebar,
    KFieldGroup,
  },
  props: {
    filters: {
      type: Object,
    },
  },
  data: () => ({
    form: {},
  }),
  watch: {
    filters: {
      handler(values) {
        this.form = { ...this.form, ...values };
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    handleFilterClick() {
      this.cleanFilters(this.form);
      this.$emit('update:filters', this.form);
      this.$emit('input', false);
    },
    cleanFilters(obj) {
      for (const propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
          delete obj[propName];
        }
      }
      return obj;
    },
  },
};
</script>
